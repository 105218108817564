import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import * as MUI from '@material-ui/core';
import CartIcon from '@material-ui/icons/ShoppingCart';
import StudentIcon from '@material-ui/icons/School';
import EducatorIcon from '@material-ui/icons/Person';
import UniversityIcon from '@material-ui/icons/AccountBalance';

import PricingCard, { EducatorDialog } from '../Common/PricingCard';
import Faq from '../Common/Faq';
import SkatterUpgradeDialog from './SkatterUpgradeDialog';
import { NotifyFunction } from '../Common/utils';
import SearchResellerDialog from '../Resellers/SearchResellerDialog';

export enum SkatterProductId {
  PERPETUAL = '666407',
  ANNUAL_FIXED_SEAT = '666357',
  ANNUAL_FLOATING = '666359',
  BUNDLE = '739362'
}

export const productNames = {
  [SkatterProductId.PERPETUAL]: 'Perpetual',
  [SkatterProductId.ANNUAL_FIXED_SEAT]: 'Annual Fixed-seat',
  [SkatterProductId.ANNUAL_FLOATING]: 'Annual Floating',
  [SkatterProductId.BUNDLE]: 'Skatter + Transmutr Bundle'
};

interface Props {
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function SkatterPriceTable(props: Props) {
  const [tab, setTab] = React.useState('pro');
  const [upgradeProductId, setUpgradeProductId] = React.useState<SkatterProductId | undefined>(
    undefined
  );
  const [resellerDialogOpen, setResellerDialogOpen] = React.useState(false);
  const [educatorDialogOpen, setEducatorDialogOpen] = React.useState<
    'educator' | 'institution' | undefined
  >(undefined);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue);
  };

  const handlePayment = (
    paddleProductId: string,
    allowQuantity: boolean,
    upsell?: {
      upsellProductId: string;
      upsellTitle: string;
      upsellText: string;
      upsellAction: string;
    }
  ) => {
    // @ts-ignore
    Paddle.Checkout.open({
      product: paddleProductId,
      upsell: upsell?.upsellProductId,
      upsellTitle: upsell?.upsellTitle,
      upsellText: upsell?.upsellText,
      upsellAction: upsell?.upsellAction,
      allowQuantity: allowQuantity,
      customData: {
        // @ts-ignore
        tolt_referral: window.tolt_referral
      }
    });
  };

  return (
    <MUI.Box>
      <MUI.Box mt={4}>
        <MUI.Tabs value={tab} onChange={handleTabChange} indicatorColor='primary' centered>
          <MUI.Tab label='Professional' value='pro' />
          <MUI.Tab label='Upgrade from v1' value='up' />
          <MUI.Tab label='Educational' value='edu' />
        </MUI.Tabs>
      </MUI.Box>

      <MUI.Box mt={6} mb={8}>
        {tab === 'pro' && (
          <MUI.Container maxWidth='lg'>
            <MUI.Grid container spacing={5} alignItems='flex-start'>
              <PricingCard
                title={productNames[SkatterProductId.PERPETUAL]}
                price={119}
                currency='€'
                description={['Access to future minor v2.x updates', 'Fixed-seat license']}
                buttonText='buy'
                buttonIcon={<CartIcon />}
                buttonAction={() => handlePayment(SkatterProductId.PERPETUAL, true)}
              />

              <PricingCard
                title={productNames[SkatterProductId.ANNUAL_FIXED_SEAT]}
                price={79}
                currency='€'
                recurringInterval='/yr'
                description={['Always up to date with the latest release', 'Fixed-seat license']}
                buttonText='buy'
                buttonIcon={<CartIcon />}
                buttonAction={() =>
                  handlePayment(SkatterProductId.ANNUAL_FIXED_SEAT, true, {
                    upsellProductId: SkatterProductId.BUNDLE,
                    upsellTitle: 'Skatter + Transmutr bundle',
                    upsellText: 'Buy Skatter and Transmutr together to get 10€ off',
                    upsellAction: 'Buy Skatter+Transmutr'
                  })
                }
                bundleTitle={
                  <>
                    Buy Skatter and Transmutr together to get <b>10€ off</b>!
                  </>
                }
                bundleDescription={
                  <>
                    Transmutr is a great complement to Skatter. It converts various 3D formats to
                    SketchUp files, with powerful features such as automated render-ready materials
                    and proxies, geometry simplification, as well as essential options like scaling,
                    unit conversion, axes/origin transformations and much more.
                    <br />
                    <MUI.Link component={RouterLink} to='/transmutr' color='inherit'>
                      Learn More
                    </MUI.Link>
                  </>
                }
                bundleButtonText='buy Skatter + Transmutr'
                bundleButtonIcon={<CartIcon />}
                bundleButtonAction={() => handlePayment(SkatterProductId.BUNDLE, false)}
              />

              <PricingCard
                title={productNames[SkatterProductId.ANNUAL_FLOATING]}
                price={199}
                currency='€'
                recurringInterval='/yr'
                description={[
                  'Always up to date with the latest release',
                  'Floating license',
                  <>
                    Optional Priority Support:{' '}
                    <a href='mailto:skatter@lindale.io'>contact us for a quote</a>
                  </>
                ]}
                buttonText='buy'
                buttonIcon={<CartIcon />}
                buttonAction={() => handlePayment(SkatterProductId.ANNUAL_FLOATING, true)}
              />
            </MUI.Grid>
          </MUI.Container>
        )}

        {tab === 'up' && (
          <MUI.Container maxWidth='lg'>
            <MUI.Container maxWidth='md' style={{ marginBottom: '32px' }}>
              <MUI.Typography variant='body2' color='textSecondary'>
                If you purchased Skatter v1 <b>after July 1st 2021</b>, you can upgrade to Skatter
                v2 <b>for free</b>*. Or if you purchased v1 <b>after April 1st 2021</b>, you can
                upgrade to v2 with a <b>50% discount</b>*. Simply click "Upgrade", enter your v1
                license key, and the discount will be applied automatically.
                <br />
                <b>
                  *This does not apply to <i>Annual Floating</i>, and the discount is only applied
                  to the first year for <i>Annual Fixed-seat</i>.
                </b>
              </MUI.Typography>
            </MUI.Container>

            <MUI.Grid container spacing={5} alignItems='flex-start'>
              <PricingCard
                title={`V1 to V2 ${productNames[SkatterProductId.PERPETUAL]}`}
                price={79}
                currency='€'
                description={['Access to future minor v2.x updates', 'Fixed-seat license']}
                buttonText='upgrade'
                buttonIcon={<CartIcon />}
                buttonAction={() => setUpgradeProductId(SkatterProductId.PERPETUAL)}
              />

              <PricingCard
                title={`V1 to V2 ${productNames[SkatterProductId.ANNUAL_FIXED_SEAT]}`}
                price={49}
                currency='€'
                recurringInterval='/1st yr'
                secondaryPrice={79}
                secondaryInterval='/yr'
                description={['Always up to date with the latest release', 'Fixed-seat license']}
                buttonText='upgrade'
                buttonIcon={<CartIcon />}
                buttonAction={() => setUpgradeProductId(SkatterProductId.ANNUAL_FIXED_SEAT)}
              />

              <PricingCard
                title={`V1 to V2 ${productNames[SkatterProductId.ANNUAL_FLOATING]}`}
                price={129}
                currency='€'
                recurringInterval='/1st yr'
                secondaryPrice={199}
                secondaryInterval='/yr'
                description={['Always up to date with the latest release', 'Floating license']}
                buttonText='upgrade'
                buttonIcon={<CartIcon />}
                buttonAction={() => setUpgradeProductId(SkatterProductId.ANNUAL_FLOATING)}
              />
            </MUI.Grid>
          </MUI.Container>
        )}

        {tab === 'edu' && (
          <MUI.Container maxWidth='lg'>
            <MUI.Grid container spacing={5} alignItems='flex-start'>
              <PricingCard
                title='Student'
                price={5}
                currency='€'
                description={[
                  'Full featured, valid for 1 year',
                  'Fixed-seat license',
                  'Not for commercial use'
                ]}
                buttonText='Apply'
                buttonIcon={<StudentIcon />}
                buttonTo='/students'
              />

              <PricingCard
                title='Educator'
                price='Free'
                description={['Full featured', 'Fixed-seat license', 'Not for commercial use']}
                buttonText='Request'
                buttonIcon={<EducatorIcon />}
                buttonAction={() => setEducatorDialogOpen('educator')}
              />

              <PricingCard
                title='Institutions'
                price='Free'
                description={['Full featured', 'Floating licenses', 'Not for commercial use']}
                buttonText='Request'
                buttonIcon={<UniversityIcon />}
                buttonAction={() => setEducatorDialogOpen('institution')}
              />
            </MUI.Grid>
          </MUI.Container>
        )}
      </MUI.Box>

      <MUI.Box display='flex' justifyContent='center'>
        <MUI.Button variant='outlined' color='primary' onClick={() => setResellerDialogOpen(true)}>
          Find a reseller
        </MUI.Button>
      </MUI.Box>

      <MUI.Box mt={8} mb={8}>
        <MUI.Container maxWidth='md'>
          <Faq
            entries={[
              {
                title: 'Can I use Skatter for other purposes than rendering?',
                description: (
                  <>
                    Yes! Absolutely. If you don’t activate the “Render only” feature, Skatter will
                    generate regular components. So you can use it for whatever you want.
                  </>
                )
              },
              {
                title: 'On how many computers can I install my license?',
                description: (
                  <>
                    For each perpetual or fixed-seat license of Skatter, you can install it on no
                    more than two computers, provided that it is used on only one computer at a
                    time. For instance you can install it on both your desktop workstation and your
                    laptop.
                    <br />
                    <br />
                    Floating licenses can be installed on as many computers as you want, but can
                    only be used simultaneously on the number of seats you purchase.
                    <br />
                    If it has one seat, it can be used on only one computer at a time. The
                    application must be closed before someone else can use it.
                    <br />
                    If it has 5 seats, it can be used on 5 computers at a time, etc.
                    <br />
                    To purchase more than one seat, change the 'quantity' field during the checkout
                    process.
                  </>
                )
              },
              {
                title: 'Which SketchUp versions are supported by Skatter?',
                description: <>SketchUp 2022 and above, for both Windows and Mac OS.</>
              },
              {
                title: 'Software and Hardware requirements to run Skatter',
                description: (
                  <>
                    <b>Software :</b>
                    <br />
                    Windows 10/11
                    <br />
                    MacOS 12 or above
                    <br />
                    <br />
                    <b>Recommended hardware :</b>
                    <br />
                    Quad-core 3+ Ghz processor
                    <br />
                    8+ GB RAM{' '}
                  </>
                )
              }
            ]}
          />
        </MUI.Container>
      </MUI.Box>

      {upgradeProductId && (
        <SkatterUpgradeDialog
          productId={upgradeProductId}
          onClose={() => setUpgradeProductId(undefined)}
          notify={props.notify}
          setMaintenanceMode={props.setMaintenanceMode}
        />
      )}

      {resellerDialogOpen && (
        <SearchResellerDialog
          onClose={() => setResellerDialogOpen(false)}
          notify={props.notify}
          setMaintenanceMode={props.setMaintenanceMode}
        />
      )}

      {educatorDialogOpen && (
        <EducatorDialog
          type={educatorDialogOpen}
          email='skatter@lindale.io'
          onClose={() => setEducatorDialogOpen(undefined)}
        />
      )}
    </MUI.Box>
  );
}
