import React from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import * as MUI from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

type HeaderButtonType = {
  label?: string;
  href?: string;
  color?: 'inherit' | 'primary' | 'secondary';
  // variant?: 'text' | 'outlined' | 'contained',
  variant?: MUI.ButtonProps['variant'];

  // The button either acts as:
  // - a menu ("menuEntires" provided)
  // - a customizable trigger ("onClick" provided)
  // - a true link ("to" provided)
  menuEntries?: HeaderButtonType[];
  onClick?: (e?: any) => void;
  to?: string;
};

const renderButtons = (buttons: HeaderButtonType[]) =>
  buttons.map((b, i) => {
    return (
      <HeaderButton
        label={b.label}
        color={b.color}
        variant={b.variant}
        menuEntries={b.menuEntries}
        onClick={b.onClick}
        to={b.to}
        key={i}
      />
    );
  });

interface HeaderButtonProps extends HeaderButtonType {
  key?: React.Key;
}

function HeaderButton(props: HeaderButtonProps) {
  // menu button
  if (props.menuEntries) {
    return (
      <HeaderMenu
        entries={props.menuEntries}
        label={props.label}
        color={props.color}
        variant={props.variant}
        key={props.key}
      />
    );
  }

  // onClick button
  else if (props.onClick) {
    return (
      <MUI.Button
        style={{
          marginRight: 4,
          marginLeft: 4
        }}
        color={props.color}
        variant={props.variant}
        disableElevation
        key={props.key}
        onClick={props.onClick}
      >
        {props.label}
      </MUI.Button>
    );
  }

  // scroll button
  else if (props.to) {
    return (
      <MUI.Button
        style={{
          marginRight: 4,
          marginLeft: 4
        }}
        component={NavHashLink}
        to={props.to}
        color={props.color}
        variant={props.variant}
        disableElevation
        key={props.key}
        smooth
        scroll={scrollWithOffset}
      >
        {props.label}
      </MUI.Button>
    );
  }

  // link button
  else {
    return (
      <MUI.Link
        style={{
          marginRight: 4,
          marginLeft: 4
        }}
        component='button'
        href={props.href}
        color={props.color}
        // @ts-ignore
        variant={props.variant}
        disableElevation
        key={props.key}
      >
        {props.label}
      </MUI.Link>
    );
  }
}

interface HeaderMenuProps extends HeaderButtonProps {
  entries: HeaderButtonType[];
}

function HeaderMenu(props: HeaderMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <HeaderButton {...props} onClick={handleClick} />

      <MUI.Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {props.entries.map((e, i) => {
          if (e.to) {
            return (
              <MUI.MenuItem onClick={handleClose} dense component={Link} to={e.to}>
                {e.label}
              </MUI.MenuItem>
            );
          } else {
            return (
              <MUI.MenuItem onClick={handleClose} dense component='a' href={e.href} target='_blank'>
                {e.label}
              </MUI.MenuItem>
            );
          }
        })}
      </MUI.Menu>
    </>
  );
}

interface Props {
  logo?: string;
  buttons?: HeaderButtonType[];
}

export default function ProductHeader(props: Props) {
  const theme = MUI.useTheme();

  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const handleMobileMenuToggle = (open: boolean) => {
    setMobileMenuOpen(open);
  };

  return (
    <MUI.AppBar
      position='sticky'
      color='default'
      style={{ backgroundColor: '#fff', color: theme.palette.text.secondary }}
      elevation={0}
    >
      <MUI.Toolbar variant='dense'>
        <MUI.Box display='flex' flex={1} alignItems='center'>
          <MUI.Box display='flex'>
            <img src={props.logo} alt='Product name' style={{ height: theme.spacing(4) }} />
          </MUI.Box>

          <MUI.Box display='flex' flex={1} justifyContent='flex-end'>
            <MUI.Hidden smDown>{props.buttons && renderButtons(props.buttons)}</MUI.Hidden>

            <MUI.Hidden mdUp>
              <MUI.IconButton color='inherit' onClick={() => handleMobileMenuToggle(true)}>
                <MenuIcon />
              </MUI.IconButton>
              <MUI.Drawer
                anchor='top'
                open={mobileMenuOpen}
                onClose={() => handleMobileMenuToggle(false)}
              >
                {props.buttons && renderButtons(props.buttons)}
              </MUI.Drawer>
            </MUI.Hidden>
          </MUI.Box>
        </MUI.Box>
      </MUI.Toolbar>
    </MUI.AppBar>
  );
}
